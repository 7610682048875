import { firestore } from "../firebase/firebase";
import React, { Component } from "react";
import { isMobileOnly } from "react-device-detect";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Holder from "../auth/login/Holder";
import Intro from "../auth/login/Intro";
import Login from "../auth/login/Login";
import { UserContext } from "../auth/providers";
import {
  addLoginAnalytics,
  checkForSpecialUser,
  getProjectData,
  getProjectDataListener,
  getProjectRealTimeData,
  turnOnUserCard,
} from "../firebase/firebase";

import Home from "../Home/Home";

class Application extends Component {
  state = {
    canEnter: false,
    canShowIntro: false,
    canShowRotate: true,
  };
  componentDidMount() {
    window.app = this;

    var checkVersion = function () {
      var agent = window.navigator.userAgent,
        start = agent.indexOf("OS");
      console.log(agent);
      if (
        (agent.indexOf("iPhone") > -1 || agent.indexOf("iPad") > -1) &&
        start > -1
      ) {
        window.parent.ios_V = window.Number(
          agent.substr(start + 3, 3).replace("_", ".")
        );
        return window.parent.ios_V;
      }
      window.parent.ios_V = null;
      return 0;
    };
    const ver = checkVersion();

    var checkVersionV2 = function () {
      var agent = window.navigator.userAgent,
        start = agent.indexOf("OS");
      console.log(agent);
      if (
        (agent.indexOf("iPhone") > -1 || agent.indexOf("iPad") > -1) &&
        start > -1
      ) {
        window.parent.ios_V2 = agent.substr(start + 3, 4).replace("_", ".");

        return window.parent.ios_V2;
      }
      window.parent.ios_V2 = null;
      return 0;
    };
    const ver2 = checkVersionV2();

    var checkMacV = () => {
      const agent = window.navigator.userAgent;
      if (
        agent.includes("Mac") ||
        agent.includes("MAC") ||
        agent.includes("mac")
      ) {
        if (agent.includes("Version/15")) {
          window.parent.isMac15 = true;
        } else {
          window.parent.isMac15 = false;
        }
      }
    };
    checkMacV();

    if (isMobileOnly) {
      document.addEventListener("click", () => {
        console.log("aaa");
        var elem = document.body;

        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
          /* Safari */
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
          /* IE11 */
          elem.msRequestFullscreen();
        }
      });
    }

    // this.getTime();
    this.getCurrentTimeStampV2();
    if (isMobileOnly) {
      this.setState({
        canShowIntro: false,
      });
    } else {
      if (!sessionStorage.getItem("playedIntro")) {
        this.setState({
          canShowIntro: false,
        });
      }
    }
  }

  getCurrentTimeStampV2 = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await fetch("https://dj-timeserver.glitch.me");
        // const res = await fetch("https://dj-wtimesever.glitch.me/");
        const result = await res.json();
        var currDateWeb = new Date(result.epoch);
        window.todaysDateTimestamp = `${currDateWeb.getFullYear()}-${
          currDateWeb.getMonth() + 1
        }-${currDateWeb.getDate()}`;
        console.log(window.todaysDateTimestamp);

        let tomorrowDate = new Date(result.epoch);
        tomorrowDate.setDate(tomorrowDate.getDate() + 1);
        let tomorrowTimestamp = tomorrowDate.getTime();
        let timeInTommorrow = tomorrowTimestamp - currDateWeb.getTime();
        this.timeInTommorrowTimer = setTimeout(() => {
          this.getCurrentTimeStampV2();
        }, timeInTommorrow);

        currDateWeb = currDateWeb.getTime();
        resolve(currDateWeb);
      } catch (err) {
        reject(err);
      }
    });
  };

  getTime = () => {
    fetch("https://worldtimeapi.org/api/timezone/Etc/UTC")
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          if (result && result.datetime) {
            // let a = "2020-11-02T00:08:07.376617+05:30"
            var currDateWeb = new Date(result.datetime);
            window.todaysDateTimestamp = `${currDateWeb.getFullYear()}-${
              currDateWeb.getMonth() + 1
            }-${currDateWeb.getDate()}`;
            console.log(window.todaysDateTimestamp);
            var currDateLocal = new Date();
            var webTimeDiff = currDateWeb.getTime() - currDateLocal.getTime();
            window.webTimeDiff = webTimeDiff;
            this.setState({ webTimeDiff });

            let tomorrowDate = new Date(result.datetime);
            tomorrowDate.setDate(tomorrowDate.getDate() + 1);
            let tomorrowTimestamp = tomorrowDate.getTime();
            let timeInTommorrow = tomorrowTimestamp - currDateWeb.getTime();
            this.timeInTommorrowTimer = setTimeout(() => {
              this.getNewDate();
            }, timeInTommorrow);
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  };

  getNewDate = async () => {
    const res = await fetch("https://worldtimeapi.org/api/timezone/Etc/UTC");
    const result = await res.json();
    if (result && result.datetime) {
      var currDateWeb = new Date(result.datetime);
      window.todaysDateTimestamp = `${currDateWeb.getFullYear()}-${
        currDateWeb.getMonth() + 1
      }-${currDateWeb.getDate()}`;
      addLoginAnalytics(this.context).then(() => {
        sessionStorage.setItem("analyticsLogged", "true");
      });

      let tomorrowDate = new Date(result.datetime);
      tomorrowDate.setDate(tomorrowDate.getDate() + 1);
      let tomorrowTimestamp = tomorrowDate.getTime();
      let timeInTommorrow = tomorrowTimestamp - currDateWeb.getTime();
      this.timeInTommorrowTimer = setTimeout(() => {
        this.getNewDate();
      }, timeInTommorrow);
    }
  };

  cleanMemory = () => {
    if (this.timeInTommorrowTimer) {
      clearTimeout(this.timeInTommorrowTimer);
    }
  };

  componentWillUnmount() {
    this.cleanMemory();
  }

  appConfig = async (email) => {
    try {
      this.setState({
        canEnter: false,
      });
      // console.log("here:" + license)
      let license = email.split("@")[0];
      // window.license=license;
      // const doc = await firestore
      // .collection("profile")
      // .doc(window.license)
      // .get();
      // window.userProfileData = doc.data();
      // if (doc.data().vote) {
      window.vote = true;
      //   console.log(window.vote)
      // }
      // if (doc.data().chat) {
      window.chat = true;
      //   console.log(window.chat)
      // }
      // console.log(doc);
      // await getProjectRealTimeData();
      await getProjectData();
      //   checkForSpecialUser(userAuth, (err, link) => {
      //     if (err) {
      //       console.log(err);
      //       return;
      //     }
      //     window.specialUser = true;
      //     window.zoomLink = link;
      //   });
      //   console.log(window.jry);
      this.setState({
        canEnter: true,
      });
      // turnOnUserCard(userAuth.email);
    } catch (error) {
      console.log(error);
    }
  };

  toggleRotateScreen = (value) => {
    console.log(value ? "can show rotate" : " can not show rotate");
    this.setState({
      canShowRotate: value,
    });
  };

  render() {
    let user = this.context;
    // return(
    //     <Holder></Holder>
    // )

    return (
      <>
        {user && this.state.canEnter ? (
          <div onContextMenu={(e) => e.preventDefault()}>
            <Router>
              <Switch>
                <Route path="/">
                  <Home
                    toggleRotateScreen={this.toggleRotateScreen}
                    canShowIntro={this.state.canShowIntro}
                  ></Home>
                </Route>
              </Switch>
            </Router>
          </div>
        ) : (
          <Router>
            <Switch>
              <Route path="/">
                <Login
                  toggleRotateScreen={this.toggleRotateScreen}
                  webTimeDiff={this.state.webTimeDiff}
                  showLoggingIn={user ? true : false}
                />
              </Route>
            </Switch>
          </Router>
        )}
        {false && this.state.canShowRotate && (
          <>
            <div className="rotate-screen-popup">
              <div id="rotate">
                <div className="phone"></div>
                <div className="message">Please rotate your device!</div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
Application.contextType = UserContext;
export default Application;
