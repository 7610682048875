import React, { Component } from "react";
import './Login.css';
import { realDB, loadUser, getUserDetails } from "../../firebase/firebase";
import { ImageString } from "../../../const/assets/ImageString";
import './Login.css';

class Holder extends Component {

    state = {
        email: '',
        name: '',
        passcode: '',
        error: false,
        errorMessage: "",
        _loading: false,
        forceDisable: false,
        forceUpdate: false,
    };

    componentDidMount = () => {
        window.showLoginError = this.showLoginError
    }

    onInputChange = event => {
        event.preventDefault();
        let value = event.target.value
        this.setState({ [event.target.name]: value });
    };

    showLoginError = (err) => {
        this.setState({
            error: true,
            errorMessage: err.message ? err.message : err
        });
    }

    validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    onFormSubmit = async (event) => {
        event.preventDefault();
        try {
            this.setState({
                error: false,
                forceDisable: true
            })
            let userPasscode = this.state.passcode;
            const userEmail = userPasscode.toLowerCase() + "@event.com";
            const password = `${userEmail}123456`;
            // let email = this.state.email.toLowerCase();
            // let name = this.state.name;
            // let password = `${email}123456`
            await loadUser(userEmail, password);
        }
        catch (err) {
            this.setState({
                forceDisable: false
            })
            let error = '';
            switch (err.code) {
                case "auth/wrong-password":
                    error = "Invalid username and password.";
                    break;
                case "auth/user-not-found":
                    error = "User not registered";
                    break;
                case "auth/too-many-requests":
                    error = "Too many invalid requests, please wait for 60 seconds before retrying";
                    break;
                case "NoUserFound":
                    error = "User not registered"
                    break
                default:
                    error = err.message
            }
            this.setState({
                error: true,
                errorMessage: error,
            })
        }
    };

    checkUserStatus = async (userId) => {
        return new Promise((resolve, reject) => {
            if (userId == undefined) {
                reject({
                    code: "UserIDWasNull",
                    message: "Please try again later"
                });
            } else {
                if (userId === "") {
                    reject({
                        code: "UserIDWasNull",
                        message: "Please try again later"
                    });
                }
            }
            var userCheck = realDB.ref('loggedInUser/' + userId)
            userCheck.once('value').then((snapshot) => {
                if (snapshot.exists()) {
                    let data = snapshot.val();
                    if (data.state === 'online') {
                        let err =
                        {
                            code: "AlreadyLogged",
                            message: "User Logged in from another system."
                        }
                        reject(err);
                    } else {
                        resolve();
                    }
                } else {
                    resolve();
                }
            }, (err) => {
                reject(err);
            })
        })
    }

    render() {
        if (this.state._loading) {
            return <img alt="loading" src="/images/loader.gif" />;
        }
        return (
            <section className="landing-page min-height-full">
                <aside className="landing-pageBox d-flex justify-content-between align-items-start min-height-full image-bg" style={{ backgroundImage: `url(${ImageString.LoginScreen})` }}>
                </aside>
                <aside className="signinBox min-height-full">
                    <form onSubmit={this.onFormSubmit}>
                        <div className="signinBox__heading">
                            <div className="left"></div>
                            {/* <div className="right"></div> */}
                        </div>
                        <div className="signinBox__body pd-t70" style={{
                            color: 'white',
                            fontSize: '2rem',
                            textAlign: 'center'
                        }}>
                            Event will be live on 11<sub style={{verticalAlign: "super"}}>th</sub> December.
                        </div>
                    </form>
                </aside>
            </section >
        );
    }

}

export default Holder;
