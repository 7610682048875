const ImageRenderedRoot = "/3dAssets/images/";
const UIRoot = "/3dAssets/UI/";
const assetsImagesFolder = "/assets/images/";

export const ImageString = {
  LOGOR1: assetsImagesFolder + "R1Logo.png",
  LOGOKOA: assetsImagesFolder + "koaLogo.png",
  LOGOSTRATETHON: assetsImagesFolder + "tutHeader.png", //STRATETHON.png',
  LOGOSEASON2: assetsImagesFolder + "SEASON2.png",

  LOGO: assetsImagesFolder + "logo.png",
  LOGOLG: assetsImagesFolder + "logo-lg.png",
  LoginScreen: assetsImagesFolder + "login/login.png",
  LOGINSCREENSIDELOGO: assetsImagesFolder + "logo.png",
  MENULOGO: assetsImagesFolder + "logo_flt.png",

  LOBBYLOOP: ImageRenderedRoot + "Lobby.jpg",
  Infodesk: ImageRenderedRoot + "Info.jpg",
  ProductStall: ImageRenderedRoot + "Product-Stall.jpg",
  Breakout: ImageRenderedRoot + "breakout.jpg",
  Audi: ImageRenderedRoot + "Audi.jpg",
  WELCOMEBACKGROUND: "assets/images/bg/all-stages.jpg",

  RIPPLE: "/3dAssets/gifs/Ripple-2.4s-128px.svg",
  //UI
  BACKBUTTON: UIRoot + "backButton.png",
  CLOSEBUTTON: UIRoot + "closeButton.png",
  BUMPERPRIZEHEADER: "assets/images/banner-header.jpg",
  PDFLOGO: UIRoot + "pdfIcon.png",
  INFOBUTTON: UIRoot + "info/information.png",

  ChatClose: UIRoot + "chatClose.png",
  ChatButton: UIRoot + "chatIcon.png",
  PollButton: UIRoot + "poll.png",
};
