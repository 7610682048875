//import { firestore } from "../../components/firebase/firebase";
import { PointType } from "../fixed/Types";
import React from "react";
import { isWindows } from "react-device-detect";

export const menuItemsId = {
  Lobby: 1,
  Audi: 2,
  TeamBuilding: 3,
  Explore: 4,
  Meet: 5,
  MyProfile: 6,
};

export const menuItemsIndex = {
  Lobby: 0,
  Audi: 1,
  TeamBuilding: 2,
  Explore: 3,
  Meet: 4,
  MyProfile: 5,
};

export const menuItems = [
  {
    id: menuItemsId.Lobby,
    name: "Lobby",
    class: "icon-Lobby",
  },
  {
    id: menuItemsId.Audi,
    name: "Auditorium",
    class: "icon-Audi",
    subMenus: [
      {
        id: 1,
        name: "Chat",
        class: "icon-flt-audi-qna",
        get enable() {
          return window.chat;
        },
      },
      {
        id: 2,
        name: "Poll",
        class: "icon-flt-audi-poll",
        get enable() {
          return window.vote;
        },
      },
      { id: 3, name: "My Profile", class: "icon-MyProfile", enable: true },
    ],
  },
  {
    id: menuItemsId.TeamBuilding,
    name: "Meet Our Leaders",
    class: "icon-Leaders",
  },
  {
    id: menuItemsId.Explore,
    name: "Explore HERO MOTORCORP",
    class: "icon-Explore icon-lg",
    subMenus: [
      // {
      //     id: 1, name: "Connect", class: "icon-Connect",
      //     // leaderboardPoint: PointType.NetworkingConnect
      // },
      // {
      //     id: 2, name: "Speaker Profile", class: "icon-Speaker",
      //     // leaderboardPoint: PointType.NetworkingSpeakerProfile
      // },
      // {
      //     id: 3, name: "Sessions", class: "icon-Sessions",
      //     // leaderboardPoint: PointType.NetworkingSession
      // },
      // {
      //     id: 4, name: "Chat", class: "icon-Chat",
      //     // leaderboardPoint: PointType.NetworkingChat
      // },
    ],
  },
  {
    id: menuItemsId.Meet,
    name: "MEET THE HERO",
    class: "icon-Hero icon-lg",
    subMenus: [],
  },
  {
    id: menuItemsId.MyProfile,
    name: "Profile",
    class: "icon-MyProfile",
  },
];

export const AudisubMenuId = {
  QNA: 1,
  POll: 2,
  Profile: 3,
};

export const NetworkingSubmenuId = {
  Connect: 1,
  Speaker: 2,
  Sessions: 3,
  Chat: 4,
};
