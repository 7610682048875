import { menuItems, menuItemsIndex } from "../../const/Menu/MenuConst";
import { VideoString } from "../../const/assets/VideoString";
import {
  TransitionType,
  HotspotType,
  HotspotButtonType,
  PointType,
} from "../../const/fixed/Types";
import { ImageString } from "../../const/assets/ImageString";
import { isMobileOnly } from "react-device-detect";

export let InfoHotspots = {
  "pdf-1": {
    id: "Agenda",
    enabled: true,
    name: "Agenda",
    desk_style: {
      margin: "1% -10%",
    },
    mobile_style: {
      margin: "3% -10.4%",
    },
    get style() {
      return isMobileOnly ? this.mobile_style : this.desk_style;
    },
    hotspotType: HotspotType.pdf,
    buttonType: HotspotButtonType.named,
    link: "/web/viewer.html?file=%2Fassets%2Fcontent%2FAgenda.pdf",
  },
  "pdf-2": {
    id: "ContactUs",
    enabled: true,
    name: "Contact Us",
    desk_style: {
      margin: "1% 1%",
    },
    mobile_style: {
      margin: "3% 1.6%",
    },
    get style() {
      return isMobileOnly ? this.mobile_style : this.desk_style;
    },
    hotspotType: HotspotType.pdf,
    buttonType: HotspotButtonType.named,
    link: "/web/viewer.html?file=%2Fassets%2Fcontent%2Fcontactus.pdf",
  },
  helpdesk: {
    id: "HelpdeskChat",
    enabled: false,
    name: "Helpdesk",
    style: {
      margin: "7% 4.5%",
    },
    hotspotType: HotspotType.chatbot,
    buttonType: HotspotButtonType.named,
  },
};

export let MeetTheHeroHotspot = {
  MeetTheHeroVideo: {
    id: "MeetTheHeroVideo",
    enabled: true,
    // name: 'Video',

    desk_style: {
      margin: "0% -6%",
    },
    mobile_style: {
      margin: "0% -6%",
    },
    get style() {
      return isMobileOnly ? this.mobile_style : this.desk_style;
    },
    hotspotType: HotspotType.video,
    // buttonType: HotspotButtonType.named,
    link: "https://vimeo.com/497278291",
  },
};
export let AudiData = {
  introVideo: VideoString.AUDITORIUM,
  // link: 'https://vimeo.com/event/473964',
  link: "https://www.youtube.com/watch?v=z1rUP17fayA",
  placementStyle: { margin: "12.6% 34.4%", width: "31.2%", height: "41%" },
};

export let TeamsData = {
  "pdf-1": {
    id: "pdf=1",
    name: "ABOUT THE ACTIVITY",
    description: "",
    link: "/assets/content/TeamBuildingInfo.pdf",
    button: "READ MORE",
  },
  link1: {
    id: "link1",
    name: "Team Leadership",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    link: "https://teams.microsoft.com/l/meetup-join/19%3ameeting_ODFmOTI3MTUtZTFmNy00NTgwLWExMzItOTI2MzkyNmFmYTlk%40thread.v2/0?context=%7b%22Tid%22%3a%2225c1df4b-00ea-4e39-98bd-5f1143c5c5df%22%2c%22Oid%22%3a%22abcb8352-f7bf-475a-82d7-cb6c48c7d70f%22%7d",
  },
  link2: {
    id: "link2",
    name: "Team Mindset",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    link: "https://www.r1rcm.com/",
  },
  link3: {
    id: "link3",
    name: "Team Values",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    link: "https://www.r1rcm.com/",
  },
  link4: {
    id: "link4",
    name: "Team Rising",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    link: "https://www.r1rcm.com/",
  },
  link5: {
    id: "link5",
    name: "Team Leadership",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    link: "https://www.r1rcm.com/",
  },
  link6: {
    id: "link6",
    name: "Team Mindset",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    link: "https://www.r1rcm.com/",
  },
  link7: {
    id: "link7",
    name: "Team Values",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    link: "https://www.r1rcm.com/",
  },
  link8: {
    id: "link8",
    name: "Team Rising",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    link: "https://www.r1rcm.com/",
  },
  link9: {
    id: "link9",
    name: "Team Values",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    link: "https://www.r1rcm.com/",
  },
  link10: {
    id: "link10",
    name: "Team Rising",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    link: "https://www.r1rcm.com/",
  },
};

export const ResourceCenterHotspot = {
  "pdf-2": {
    id: "Annual_CSR_Report_2019-2020",
    enabled: true,
    name: "Annual CSR Report 2019-2020",
    style: {
      margin: "6% 13.5%",
    },
    hotspotType: HotspotType.pdf,
    buttonType: HotspotButtonType.named,
    link: "/web/viewer.html?file=%2Fassets%2Fcontent%2FAnnualCSRReport2019-2020-High.pdf",
  },
  "pdf-3": {
    id: "ANNUAL_REPORT_FY19-20",
    enabled: true,
    name: "ANNUAL REPORT FY19-20",
    style: {
      margin: "2% 13.5%",
    },
    hotspotType: HotspotType.pdf,
    buttonType: HotspotButtonType.named,
    link: "/web/viewer.html?file=%2Fassets%2Fcontent%2FANNUAL_REPORT_FY19-20.pdf",
  },
  // 'pdf-4': {
  //     id: 'pdf-4',
  //     enabled: true,
  //     name: 'Hero Campus Reach Out',
  //     style: {
  //         margin: '11% 13.5%',
  //     },
  //     hotspotType: HotspotType.pdf,
  //     buttonType: HotspotButtonType.named,
  //     link: "/web/viewer.html?file=%2Fassets%2Fcontent%2FHero_Campus_Reach_Out.pdf",
  // },
  "pdf-5": {
    id: "Hero_Campus_Recuitment_Program",
    enabled: true,
    name: "Hero Campus Recuitment Program",
    style: {
      margin: "11% 13.5%", //'15% 13.5%',
    },
    hotspotType: HotspotType.pdf,
    buttonType: HotspotButtonType.named,
    link: "/web/viewer.html?file=/assets/content/HeroCampusRecuitmentProgram.pdf",
    // link: "/web/viewer.html?file=%2Fassets%2Fcontent%2FHeroCampusRecuitmentProgram.pdf",
  },
  videoPlayer: {
    id: "Videos",
    enabled: true,
    name: `Videos`,
    style: {
      margin: "6% -28%",
    },
    hotspotType: HotspotType.videoPlayer,
    buttonType: HotspotButtonType.named,
  },
};
// /web/viewer.html?file=%2F
export let LobbyHotspots = {
  PhotoBooth: {
    id: "PhotoBooth",
    enabled: true,
    style: {
      margin: "2% -20%",
    },
    hotspotType: HotspotType.iframe,
    link: "/photobooth/index.html",
    //leaderboardPoint: PointType.Photobooth,
  },
  Leader: {
    id: "Leader",
    enabled: true,
    style: {
      margin: "-5.5% -29%",
    },
    hotspotType: HotspotType.transition,
    transitionVideo: null,
    transitionType: TransitionType.changeOverlayComponent,
    newItem: menuItems[menuItemsIndex.TeamBuilding],
  },
  Audi: {
    id: "Audi",
    enabled: true,
    style: {
      margin: "-5.5% -3.6%",
    },
    hotspotType: HotspotType.transition,
    transitionVideo: VideoString.LOBBYTOAudi,
    transitionType: TransitionType.changeComponent,
    newItem: menuItems[menuItemsIndex.Audi],
  },
  Explore: {
    id: "ResourceCenter",
    enabled: true,
    style: {
      margin: "-6% 22.5%",
    },
    hotspotType: HotspotType.transition,
    transitionVideo: VideoString.LOBBYTOBREAKOUT,
    transitionType: TransitionType.changeComponent,
    newItem: menuItems[menuItemsIndex.Explore],
    hotspot: ResourceCenterHotspot,
  },
  Game: {
    id: "Game",
    enabled: true,
    style: {
      margin: "2% 12.5%",
    },
    hotspotType: HotspotType.iframe,
    link: "/game/index.html",
    //leaderboardPoint: PointType.Game,
  },
  Infodesk: {
    id: "Infodesk",
    enabled: true,
    style: {
      margin: "6% -4%",
    },
    hotspotType: HotspotType.transition,
    transitionVideo: VideoString.LOOBYTOINFO,
    transitionType: TransitionType.withInComponent,
    newItem: ImageString.Infodesk,
    hotspot: InfoHotspots,
    name: "Infodesk",
    //leaderboardPoint: PointType.InfoDesk,
  },
  quiz: {
    id: "quiz",
    enabled: true,
    style: {
      margin: "7% -16%",
    },
    hotspotType: HotspotType.iframe,
    link: "/wordScramble/index.html",
    name: "quiz",
    //leaderboardPoint: PointType.InfoDesk,
  },
  meetTheHero: {
    id: "meetTheHero",
    enabled: true,
    style: {
      margin: "-5.5% 35%",
    },
    hotspotType: HotspotType.transition,
    transitionVideo: VideoString.LOOBYTOPRODUCTSTALL,
    transitionType: TransitionType.changeComponent,
    newItem: menuItems[menuItemsIndex.Meet],
    hotspot: MeetTheHeroHotspot,
  },
  Photomosaic: {
    id: "Photomosaic",
    enabled: true,
    style: {
      margin: "-5.5% -43%",
    },
    hotspotType: HotspotType.iframe,
    link: "https://photomosaic3.djvirtualevents.com/",
    name: "Photomosaic",
  },
};

export let NetworkingLounge = {
  speakerPdf: "/web/viewer.html?file=%2Fassets%2Fcontent%2FSpeakers.pdf",
};
