// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";
import { ImageString } from "../../../const/assets/ImageString";
import "./Tut.css"

const states = {
    introCard: 0,
    tutCard: 1,
    leaderboard: 2
}

const HeaderBackground = {
    background: '#fff',//'url(/assets/images/background.png)',
    // backgroundPosition: 'center',
    // backgroundSize: 'contain'
}

const TutCard = [
    {
        link: "/3dAssets/UI/Tut/arrows.png",
        header: "Arrows",
        description: " Tap the left and right arrows to view more."
    },
    {
        link: "/3dAssets/UI/Tut/hotspot.png",
        header: "Hotspots",
        description: "Tap on any hotspot to enter that section."
    },
    {
        link: "/3dAssets/UI/Tut/Menu.png",
        header: "Menu",
        description: "Use the menu icons for quick navigation"
    },
]

export default class LobbyTut extends Component {
    state = {
        currentState: states.introCard
    }

    handleContinue = (event) => {
        if (event) {
            event.preventDefault();
        }
        this.props.skip();
    }

    showNextCard = (event) => {
        if (event) {
            event.preventDefault();
        }
        this.setState(prev => ({
            currentState: prev.currentState + 1
        }))
    }

    render() {
        return (
            <>
                <div className="lobbyTutContainer"
                >
                    {
                        this.state.currentState === states.introCard &&
                        <>
                            <div className="tutCardContainer width-cal">

                                <div className="signinBox__heading__container">
                                    <div className="tutCardContainer-header ptb-extra justify-content-between  w-95 justify-content-center"
                                        style={HeaderBackground}>
                                        <img src={ImageString.LOGOSTRATETHON} alt="logo1" style={{ width: '12.5rem' }}></img>
                                        {/* <img src={ImageString.LOGOSEASON2} alt="logo2"></img> */}
                                    </div>
                                    <div className="signinBox__heading__gradient"></div>
                                </div>

                                <div className="tutCardContainer-body tutCardContainer-body-extraPd">
                                    <div className="tutCardContainer-body-textContainer">
                                        <strong><h3>Welcome to the Grand Finale!</h3></strong>
                                        <br></br>
                                        We are excited to have you with us today. 10 finalist teams from across 1700+ campuses over 35,000 participants have made it here today. Come join us in cheering them as they realize their true potential.
                                        <br></br>
                                        <button className="tutCardButton btn  btn-yellow mg-t30" onClick={(e) => this.showNextCard(e)}>Let's Start</button>
                                    </div>
                                </div>

                            </div>
                        </>
                    }
                    {
                        this.state.currentState === states.tutCard &&
                        <div className="tutCardContainer tutCardContainer-sm pd-b-2">
                            <div className="tutCardContainer-header  bg-red">
                                <div>Tutorial</div>
                            </div>
                            <div className="tutCardContainer-body ">
                                {
                                    TutCard.map(item => (
                                        <div key={item.header} className="tutCardContainer-bodyItem">
                                            <img alt={item.header} src={item.link}></img>
                                            <div className="bodyItem-text">
                                                <h2>{item.header}</h2>
                                                <div>
                                                    {item.description}
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="text-center">
                                <button className="tutCardButton btn  btn-yellow mg-t30" onClick={(e) => this.handleContinue(e)}>Continue</button>
                            </div>
                        </div>
                    }
                </div>
            </>
        )
    }
}
