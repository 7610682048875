import React, { Component } from "react";
import "./Login.css";
import { ImageString } from "../../../const/assets/ImageString";
import "./Login.css";
import { isMobileOnly } from "react-device-detect";
const HeaderBackground = {
  background: "#fff",
};
export default class Intro extends Component {
  state = {
    UI: {
      showTransitionVideo: false,
      loading: false,
    },
  };
  videoRef = React.createRef();

  handleContinue = () => {
    console.log("sssssssssssssssss");
    if (this.videoRef) {
      let video = this.videoRef.current;
      video.volume = 0.65;
      video.play();
    }
    this.setState((prev) => ({
      UI: {
        ...prev.UI,
        loading: true,
      },
    }));
  };

  handleTransitionVideoStart = () => {
    console.log("start");
    this.setState((prev) => ({
      UI: {
        ...prev.UI,
        loading: false,
        showTransitionVideo: true,
      },
    }));
  };

  handleTransitionVideoTagEnd = () => {
    console.log("end");
    if (this.props.close) {
      this.props.close();
    }
  };

  componentWillUnmount() {
    if (this.videoRef) {
      if (this.videoRef.current) this.videoRef.current.remove();
    }
  }

  render() {
    return (
      <>
        {this.state.UI.loading && (
          <div className="UILoader">
            <img src="/3dAssets/UI/uiLoader.gif" alt="loading..."></img>
          </div>
        )}
        <div className="main3DContainer">
          <div id="child3DContainer">
            <video
              className={`IntroTransitionVideo ${
                this.state.UI.showTransitionVideo ? "fadeInTransitionVideo" : ""
              } `}
              width="320"
              height="240"
              loop={false}
              preload="auto"
              playsInline={true}
              ref={this.videoRef}
              onPlay={this.handleTransitionVideoStart}
              onEnded={this.handleTransitionVideoTagEnd}
            >
              <source src={"/3dAssets/videos/Intro.mp4"} type="video/mp4" />
              Your browser does not support HTML5 video.
            </video>
          </div>
        </div>

        <section className="landing-page min-height-full">
          <aside
            className="landing-pageBox d-flex justify-content-between align-items-start min-height-full image-bg"
            style={
              isMobileOnly
                ? {
                    backgroundImage: `url(${ImageString.LoginScreen})`,
                    position: "absolute",
                    height: "100vh",
                  }
                : {
                    backgroundImage: `url(${ImageString.LoginScreen})`,
                    position: "absolute",
                  }
            }
          ></aside>

          {/* <aside className="signinBox min-height-full">
                        <div className="signinBox__heading__container">
                            <div className="signinBox__heading">
                                <div className="left"></div>
                            </div>
                            <div className="signinBox__heading__gradient"></div>
                        </div>
                        <br></br>
                        <div className="loaderContainer">
                            <div className="loaderContainer-msg">
                                <strong><h3>Hi user,</h3></strong>
                            We are excited to have you with us today. 12 finalist teams from across 132 colleges, 5 countries, over 10,000 participants have made it here today. Come join us in cheering them on they realise their true potential.
                        <button className="tutCardButton btn  btn-yellow mg-t30" onClick={(e) => this.handleContinue(e)}>Let's Explore</button>
                            </div>
                        </div>
                    </aside> */}
          {!this.state.UI.showTransitionVideo && (
            <div className="lobbyTutContainer">
              <div className="tutCardContainer width-cal">
                <div className="signinBox__heading__container">
                  <div
                    className="tutCardContainer-header ptb-extra justify-content-between  w-95 justify-content-center"
                    style={HeaderBackground}
                  >
                    <img
                      src={ImageString.LOGOSTRATETHON}
                      alt="logo1"
                      style={{ width: "12.5rem" }}
                    ></img>
                    {/* <img src={ImageString.LOGOSEASON2} alt="logo2"></img> */}
                  </div>
                  <div className="signinBox__heading__gradient"></div>
                </div>

                <div className="tutCardContainer-body ">
                  <div
                    className="tutCardContainer-body-textContainer text-center"
                    style={{ fontSize: "1.8rem" }}
                  >
                    <strong>
                      <h2 style={{ textAlign: "center", fontWeight: "bold" }}>
                        Welcome To
                      </h2>
                    </strong>
                    The Hero Campus Challenge <br></br>Season 6<br></br>
                    <button
                      className="tutCardButton btn  btn-yellow mg-t30"
                      onClick={(e) => this.handleContinue(e)}
                    >
                      Explore
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
      </>
    );
  }
}
