// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";
import './CustomNotification.css'

class CustomNotification extends Component {

    state = {
        show: false,
        message: ''
    }

    componentDidMount() {
        window.NotificationListener = this.props.firestore.collection('backStage').doc('notifications').onSnapshot((doc) => {
            if (doc.exists) {
                // console.log(doc.data())
                let data = doc.data()
                if (data.testShow) {
                    this.showNotification(data.message, data.time);
                }
            }
        })
    }

    componentWillUnmount() {
        if (window.NotificationListener) {
            window.NotificationListener();
        }
    }

    notificationTimerRef = null
    showNotification = (message, timeLimit = 15) => {
        if (typeof (timeLimit) == 'string') {
            timeLimit = parseInt(timeLimit);
        }
        if (this.notificationTimerRef) {
            clearTimeout(this.notificationTimerRef);
        }
        this.setState({
            show: true,
            message: message
        })
        this.notificationTimerRef = setTimeout(() => {
            this.setState({
                show: false,
            })
        }, timeLimit * 1000)
    }

    closeNotification = (e) => {
        if (e) {
            e.preventDefault();
        }
        if (this.notificationTimerRef) {
            clearTimeout(this.notificationTimerRef);
        }
        this.setState({
            show: false,
        })
    }

    render() {
        return (
            <>
                <div className={`notificationContainer ${this.state.show ? 'notification-show' : 'notification-hidden'}`}>
                    <div className="closeButtonContainer">
                        <div className="closeButton-notification" onClick={this.closeNotification}></div>
                    </div>
                    {this.state.message}
                </div>
            </>
        )
    }
}

export default CustomNotification;