// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";
import "./Tut.css";
import continueBtn from "./tutButton.png";

const TutCard = [
  {
    link: "/3dAssets/UI/AudiTut/FullScreen.jpg",
    header: "Full Screen",
    description: "Click on the icon to view the content on full screen.",
  },
  {
    link: "/3dAssets/UI/AudiTut/ExitFullScreen.jpg",
    header: "Exit Full Screen",
    description: "Click on this button to exit the fullscreen mode.",
  },
  {
    link: "/3dAssets/UI/AudiTut/PlayButton.jpg",
    header: "Play Button",
    description: "Click on the play button to start playing the video.",
  },
  {
    link: "/3dAssets/UI/AudiTut/Polls.jpg",
    header: "Poll",
    description: "Click on this button to cast your vote.",
  },
  {
    link: "/3dAssets/UI/AudiTut/Q&A.jpg",
    header: "Q&A",
    description: "Click here to get answers to your queries.",
  },
];

const state = {
  audiTut: 0,
  zoomLink: 1,
};

export default class AudiTut extends Component {
  state = {
    currentState: state.audiTut,
  };

  handleContinue = (event) => {
    if (event) {
      event.preventDefault();
    }
    if (window.specialUser) {
      if (this.state.currentState === state.audiTut) {
        this.setState({
          currentState: state.zoomLink,
        });
      } else {
        this.props.skip();
      }
    } else {
      this.props.skip();
    }
  };

  call = (event) => {
    if (event) {
      event.preventDefault();
    }
    window.location.href = window.zoomLink;
  };

  render() {
    return (
      <>
        {this.state.currentState === state.audiTut && (
          <div className="lobbyTutContainer">
            <div className="tutCardContainer tutCardContainer-sm pd-b-2">
              <div className="tutCardContainer-header">
                <div>Tutorial</div>
              </div>
              <div className="tutCardContainer-body">
                {TutCard.map((item) => (
                  <div key={item.header} className="tutCardContainer-bodyItem">
                    <img alt={item.header} src={item.link}></img>
                    <div className="bodyItem-text">
                      <h2>{item.header}</h2>
                      <div>{item.description}</div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="text-center">
                <input
                  type="image"
                  className="continueBtn"
                  onClick={(e) => this.handleContinue(e)}
                  src={continueBtn}
                  name="submit"
                  disabled={this.state.forceDisable ? true : false}
                  style={{
                    //marginLeft:"-35%",
                    width: "35%",
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {this.state.currentState === state.zoomLink && (
          <div className="lobbyTutContainer">
            <div className="tutCardContainer tutCardContainer-sm pd-b-2">
              <div className="tutCardContainer-header  bg-red">
                <div>Zoom Call</div>
              </div>
              <div className="tutCardContainer-body">
                Please click on button below to go to the zoom link.
              </div>
              <div className="text-center">
                <button
                  className="tutCardButton btn  btn-yellow mg-t30"
                  onClick={(e) => this.handleContinue(e)}
                >
                  Cancel
                </button>
                <button
                  className="tutCardButton btn  btn-yellow mg-t30"
                  onClick={(e) => this.call(e)}
                  style={{ marginLeft: "1rem" }}
                >
                  Call
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
