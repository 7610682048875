import React, { Component } from "react";
import { UserContext } from "../auth/providers";
//#region package Import
import swal from "sweetalert";
import ReactPlayer from "react-player";
//#endregion
import "./loader.css";
//#region importing components
import Menu from "./Menu/Menu";
import MyProfile from "./MyProfile/MyProfile";
import Scene from "../common/Scene/Scene";
import QNA from "../Qna/App1";
import PublicPoll from "../Poll/PublicPoll";
//#endregion

//#region Const import
import {
  AnalyticsLocations,
  HotspotType,
  PointType,
  PointValues,
} from "../../const/fixed/Types";
import { AppString } from "../firebase/AppString";

import {
  AudiData,
  LobbyHotspots,
  MeetTheHeroHotspot,
  NetworkingLounge,
  ResourceCenterHotspot,
  TeamsData,
} from "../../data/Lobby/hotspots";
import {
  AudisubMenuId,
  menuItems,
  menuItemsId,
  menuItemsIndex,
  NetworkingSubmenuId,
} from "../../const/Menu/MenuConst";
import { VideoString } from "../../const/assets/VideoString";
import { ImageString } from "../../const/assets/ImageString";
import VideoPlayer from "../common/videoPlayer/VideoPlayer";
import { PDFPlayerData, VideoPlayerData } from "../../data/Lobby/realtimeData";
import AudiScene from "../common/AudiScene/AudiScene2";
import {
  decreaseLocationCount,
  firestore,
  updateUserLocation,
  addRealtimeHotspotAnalytics,
  addScore,
  getAudiLinkListener,
  removeAudiLinkListener,
  addLogoutAnalytics,
  signOut,
} from "../firebase/firebase";
import LobbyTut from "./Tut/LobbyTut";
import ReactAudioPlayer from "react-audio-player";
import CustomNotification from "../common/CustomNotification/CustomNotification";
import AudiTut from "./Tut/AudiTut";
import NetworkingTut from "./Tut/NetworkingTut";
import Connect from "../Connect/Connect";
import ClientChat from "../client-chat/ClientChat";
// import ReactTooltip from "react-tooltip";
// import NotLive from "../NotLive/NotLive";
// import ListContainer from "./ListContainer/ListContainer";
// import Leaderboard from "../Leaderboard/Leaderboard";
import PdfPlayer from "../common/PdfPlayer/PdfPlayer";
import Intro from "../auth/login/Intro";
import letsGo from "./letsGo.png";
import {
  isIOS,
  isIPad13,
  isMobileOnly,
  isMobileSafari,
  isTablet,
} from "react-device-detect";
import downloadPdf from "./downloadPdf.png";

//#endregion

const videoRatios = 2.33;

class Home extends Component {
  state = {
    UI: {
      menuItems: menuItems,
      activeMenu: menuItems[1],
      activeSubMenu: null,
      lastMenu: menuItems[1],
      overlayMenu: null,
      MenuVisible: true,
      isInteractable: true,
      showMediaModal: false,
      clicked: false,
      showHelpdeskChat: false,
      showLeaderboard: false,
      showLeaderboardButton: true,
      showIntro: this.props.canShowIntro,
    },
    HelpdeskChat: {
      showChat: false,
      showButton: true,
    },
    MediaModalInfo: {
      link: "",
      type: null,
    },
    audiLnk: AudiData.link,
    showSpeakerButton: false,
    speakerUrl: "",
    showLoader: true,
  };
  currentOverlayCallback = null;
  currentAudioRef = React.createRef();
  AudioListenerRemoved = false;

  componentWillMount = () => {
    // firestore
    //   .collection("profile")
    //   .doc(window.license)
    //   .get()
    //   .then((doc) => {
    //     if (doc.data().isSpeaker) {
    //       this.setState({
    //         showSpeakerButton: true,
    //       });
    //     }
    //   });
    // firestore
    //   .collection("backstage")
    //   .doc("url")
    //   .get()
    //   .then((doc) => {
    //     if (doc.exists) {
    //       this.setState({
    //         speakerUrl: doc.data().speakerUrl,
    //       });
    //     }
    //   });
  };
  componentDidMount = () => {
    setInterval(() => {
      this.setState({
        showLoader: false,
      });
    }, 2000);

    window.c = this;

    // getProjectData();

    getAudiLinkListener((err, link) => {
      if (err) {
        console.log(err);
        return;
      }
      this.setState({
        audiLnk: link,
      });
    });
    window.addEventListener("click", this.playIntroAudio);
  };

  componentWillUnmount() {
    if (!this.AudioListenerRemoved) {
      this.AudioListenerRemoved = true;
      window.removeEventListener("click", this.playIntroAudio);
    }
    removeAudiLinkListener();
  }

  playIntroAudio = () => {
    // console.log(this.currentAudioRef)
    if (this.currentAudioRef.current)
      this.currentAudioRef.current.audioEl.current.play();
    this.AudioListenerRemoved = true;
    window.removeEventListener("click", this.playIntroAudio);
  };

  //#region UI Function
  showInfoPopUp(message) {
    swal({
      title: message,
      icon: "info",
      className: "video-swal-modal",
      button: "continue",
    });
  }
  openLastMenu = (event) => {
    if (event) event.preventDefault();
    this.setState((state) => ({
      UI: { ...state.UI, activeMenu: state.UI.lastMenu, overlayMenu: null },
    }));
  };

  handleClick = (event, item, overlayCloseCallback) => {
    if (event) event.preventDefault();
    this.closeMediaModal();
    console.log(item);

    if (this.state.UI.overlayMenu) {
      if (item.id === this.state.UI.overlayMenu.id) {
        return;
      }
    } else if (item.id === this.state.UI.activeMenu.id) {
      return;
    }

    if (overlayCloseCallback) {
      this.currentOverlayCallback = overlayCloseCallback;
    }

    if (
      item.id !== menuItemsId.MyProfile &&
      item.id !== menuItemsId.TeamBuilding
    ) {
      this.handleHelpdeskChatClose();
      this.setState((state) => ({
        UI: {
          ...state.UI,
          activeMenu: item,
          overlayMenu: null,
          lastMenu: state.UI.activeMenu,
          activeSubMenu: null,
          showHelpdeskChat: false,
          showLeaderboard: false,
          showLeaderboardButton: true,
        },
      }));
    } else {
      this.setState((state) => ({
        UI: { ...state.UI, overlayMenu: item, lastMenu: state.UI.activeMenu },
      }));
    }
  };

  logout = (event) => {
    event.preventDefault();
    addLogoutAnalytics(this.context).then(() => {
      signOut();
    });
  };

  handleSubmenuItemClick = (event, item) => {
    if (event) event.preventDefault();
    if (this.state.UI.activeSubMenu)
      if (this.state.UI.activeSubMenu.id === item.id) {
        return;
      }

    // if (item.id === 3) {
    //   this.logout(event);
    //   return;
    // }

    this.setState((prevState) => ({
      UI: {
        ...prevState.UI,
        activeSubMenu: item,
      },
    }));

    if (this.state.UI.activeMenu.id === menuItemsId.Networking) {
      this.addHotspotAnalytics(item.name);
    }
  };

  closeSubMenu = (event) => {
    if (event) event.preventDefault();
    this.setState((prevState) => ({
      UI: {
        ...prevState.UI,
        activeSubMenu: null,
        showMediaModal: false,
      },
      MediaModalInfo: {
        ...prevState.MediaModalInfo,
        link: "",
        type: null,
      },
    }));
  };

  hideOverlayMenu = (event) => {
    if (event) event.preventDefault();
    if (this.state.UI.overlayMenu) {
      this.setState((state) => ({
        UI: { ...state.UI, overlayMenu: null, showAskExpertError: false },
      }));
    }
    if (this.currentOverlayCallback) {
      this.currentOverlayCallback();
      this.currentOverlayCallback = null;
    }
  };

  showMediaModal = (hotspotType, mediaLink) => {
    if (hotspotType === HotspotType.chatbot) {
      window.lastChatBotRef = mediaLink;
      mediaLink.enabled = false;
      this.handleHelpdeskChatbutton();
      return;
    }
    this.setState((prevState) => ({
      UI: {
        ...prevState.UI,
        showMediaModal: true,
      },
      MediaModalInfo: {
        ...prevState.MediaModalInfo,
        link: mediaLink ? mediaLink : "",
        type: hotspotType,
      },
    }));
  };

  handleMedialModalFromOverlay = (hotspotType, link) => {
    this.hideOverlayMenu();
    this.showMediaModal(hotspotType, link);
  };

  closeMediaModal = () => {
    this.setState((prevState) => ({
      UI: {
        ...prevState.UI,
        showMediaModal: false,
      },
      MediaModalInfo: {
        ...prevState.MediaModalInfo,
        link: "",
        type: null,
      },
    }));
  };
  //#endregion

  handleTeamOptionSelection = (event, teams) => {
    // event.preventDefault();
    window.open(teams.link, "_blank");
    this.hideOverlayMenu(event);
  };

  checkIfAudioCanPlayUnderMediaModal = () => {
    if (this.state.UI.activeMenu.id === menuItemsId.Networking) {
      if (this.state.UI.activeSubMenu)
        if (this.state.UI.activeSubMenu.id === NetworkingSubmenuId.Sessions) {
          return false;
        }
    }
    if (this.state.UI.showMediaModal) {
      if (this.state.MediaModalInfo.type === HotspotType.pdf) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  handleHelpdeskChatClose = () => {
    if (window.lastChatBotRef) window.lastChatBotRef.enabled = true;
    if (this.state.HelpdeskChat.showChat) {
      this.setState({
        HelpdeskChat: {
          showChat: false,
          showButton: true,
        },
      });
    }
  };

  handleHelpdeskChatbutton = () => {
    if (this.state.HelpdeskChat.showButton) {
      this.setState({
        HelpdeskChat: {
          showChat: true,
          showButton: false,
        },
      });
    }
  };

  handleLobbyInternalState = (internalState) => {
    switch (internalState) {
      case "Infodesk":
        this.setState((prevState) => ({
          UI: {
            ...prevState.UI,
            showHelpdeskChat: true,
          },
        }));
        break;
      default:
        this.setState((prevState) => ({
          UI: {
            ...prevState.UI,
            showHelpdeskChat: false,
          },
          HelpdeskChat: {
            showChat: false,
            showButton: true,
          },
        }));
    }
  };

  handleSceneTutorial = (type) => {
    if (type === "end") {
      this.setState((prevState) => ({
        UI: {
          ...prevState.UI,
          isInteractable: true,
        },
      }));
    }
  };

  //#region Analytics
  handleHotspotAnalytics = (hotspotDetails) => {
    console.log(hotspotDetails.id);
    this.addHotspotAnalytics(hotspotDetails.id);
  };

  addComponentAnalytics = (location, value) => {
    //add to user
    if (value) this.addHotspotAnalytics(location);
    //add to current status)
    if (location !== AnalyticsLocations.Teambuilding)
      this.addLocationAnalytics(location, value);
  };

  addLocationAnalytics = (location, value) => {
    if (value) {
      // add
      updateUserLocation(this.context, location);
    } else {
      // remove
    }
  };

  addHotspotAnalytics = (name) => {
    //call some firebase function
    addRealtimeHotspotAnalytics(this.context, name);
  };
  //#endregion

  //#region LeaderBoard
  addScorePoints = (point) => {
    console.log("pooint->" + point);
    addScore(this.context, point);
  };
  handleLeaderboardClose = () => {
    this.setState((prevState) => ({
      UI: {
        ...prevState.UI,
        showLeaderboard: false,
        showLeaderboardButton: true,
      },
    }));
  };
  handleLeaderboardButton = () => {
    this.setState((prevState) => ({
      UI: {
        ...prevState.UI,
        showLeaderboard: true,
        showLeaderboardButton: false,
      },
    }));
  };

  // handleMedialModalFromOverlay
  canShowLeaderboardInNet = () => {
    if (this.state.UI.activeMenu.id === menuItemsId.Networking) {
      if (this.state.UI.activeSubMenu) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };
  //#endregion

  closeIntro = () => {
    this.setState((prevState) => ({
      UI: {
        ...prevState.UI,
        showIntro: false,
      },
    }));
    sessionStorage.setItem("playedIntro", "true");
  };

  render() {
    return (
      <>
        <a
          href="/assets/content/neustropdf/SINGLE AUDIT PROCESS.pdf"
          download="SINGLE AUDIT PROCESS.pdf"
          id="audi-pdf-1"
          style={{ display: "none" }}/>
        <a
          href="/assets/content/neustropdf/UNIFORM GUIDANCE 2CFR 200.pdf"
          download="UNIFORM GUIDANCE 2CFR 200.pdf"
          id="audi-pdf-2"
          style={{ display: "none" }} />
        {/* <a
          href="/assets/content/flt/NUEVAS TENDENCIAS FINANCIERAS POST PANDEMIA.pdf"
          download="NUEVAS TENDENCIAS FINANCIERAS POST PANDEMIA.pdf"
          id="audi-pdf-3"
          style={{ display: "none" }}
        ></a> */}
        {/* <div
          onClick={() => {
            // var aFirst = document.createElement('a');

            document.getElementById("audi-pdf-1").click();
            document.getElementById("audi-pdf-2").click();
            // document.getElementById("audi-pdf-3").click();
          }}
        >
          <input
            type="image"
            src={downloadPdf}
            className="letsGo"
            style={{
              width: "131px",
              marginTop: "2rem",
              // marginBottom: "auto",
              marginRight: isMobileOnly ? "1rem" : "10rem",
              position: "absolute",
              right: "0",
              top: "0",
              border: "none",
              cursor: "pointer",
              zIndex: "1",
            }}
          />
        </div> */}
        {/* <a
          href="/assets/content/ANUARIO ASAMBLEA GENERAL SOCIOS 2021 - FINAL.pdf"
          download="ANUARIO ASAMBLEA GENERAL SOCIOS 2021 - FINAL.pdf"
        >
          <input
            type="image"
            src={downloadPdf}
            className="letsGo"
            style={{
              width: "131px",
              marginTop: "2rem",
              // marginBottom: "auto",
              marginRight: "10rem",
              position: "absolute",
              right: "0",
              top: "0",
              border: "none",
              cursor: "pointer",
              zIndex: "1",
            }}
          />
        </a> */}
        {/* {
        isMobileOnly &&   <div 
          style={{
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
            position:"absolute",
            height:"100vh",
            width:"100vw",
            top:"0",
            left:"0",
            background:"black",
            zIndex:"10000000",
            color:"white",
            fontSize:"20px"
          }}>
          Desktop support only.
        </div>
      } */}
        {this.state.showLoader && (
          <div
            className="loader"
            style={{
              background: "black",
              height: "100vh",
              width: "100vw",
              position: "absolute",
              top: "0",
              left: "0",
              zIndex: "1000",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span style={{ fontSize: "30px", color: "white" }}>
              <div className="lds-dual-ring"></div>
            </span>
          </div>
        )}
        {false && this.state.showSpeakerButton && (
          <span
            style={{
              position: "absolute",
              left: "50%",
              bottom: "1em",
              zIndex: "2",
            }}
          >
            <div
              style={{
                width: "300px",
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                left: "-50%",
                background: "#8EC640",
                zIndex: "1",
                padding: "1em",
                color: "white",
                borderRadius: "10px",
                fontSize: "15px",
              }}
            >
              <span>Fila para hablar</span>
              <a href={this.state.speakerUrl}>
                <input
                  type="image"
                  src={letsGo}
                  className="letsGo"
                  style={{
                    //flexGrow: "1",
                    width: "131px",
                    // marginTop: "auto",
                    // marginBottom: "auto",
                    //marginRight: "100px",
                    border: "none",
                    cursor: "pointer",
                    zIndex: "1",
                  }}
                />
              </a>
            </div>
          </span>
        )}
        {this.state.UI.showIntro && <Intro close={this.closeIntro}></Intro>}
        {!this.state.UI.showIntro && (
          <>
            <div className="wrapper">
              <section
                className={`loggedin-page has-right-menu min-height-full min-height-full image-bg  ${
                  isIOS ? "ios-page" : ""
                } ${isIPad13 ? "on-ipad" : ""} ${
                  window.parent.ios_V
                    ? window.parent.ios_V === 15
                      ? "ios-15"
                      : ""
                    : ""
                } ${
                  window.parent.ios_V
                    ? window.parent.ios_V === 15 && !isMobileSafari
                      ? "ios-15-ch"
                      : ""
                    : ""
                } ${
                  window.parent.ios_V2
                    ? window.Number(window.parent.ios_V2) >=
                      window.Number("14.2")
                      ? "ios-14-2"
                      : ""
                    : ""
                }`}
              >
                <CustomNotification firestore={firestore}></CustomNotification>
                {this.state.UI.showMediaModal && (
                  <>
                    <article
                      className={`img-bg videoBox h-100 zIndex-16`}
                      id="play"
                    >
                      <div className="media-modal">
                        {this.state.MediaModalInfo.type ===
                          HotspotType.pdfPlayer && (
                          <PdfPlayer
                            data={PDFPlayerData}
                            close={() => this.closeMediaModal()}
                          ></PdfPlayer>
                        )}
                        {this.state.MediaModalInfo.type ===
                          HotspotType.videoPlayer && (
                          <VideoPlayer
                            videoData={VideoPlayerData}
                            close={() => this.closeMediaModal()}
                          ></VideoPlayer>
                        )}
                        {this.state.MediaModalInfo.type === HotspotType.pdf && (
                          <iframe
                            title={"pdf"}
                            className="media-modal-content"
                            src={this.state.MediaModalInfo.link}
                          ></iframe>
                        )}
                        {this.state.MediaModalInfo.type ===
                          HotspotType.iframe && (
                          <iframe
                            title={"iframe"}
                            allow="camera *;microphone *"
                            className="media-modal-content-iframe"
                            src={this.state.MediaModalInfo.link}
                          ></iframe>
                        )}
                        {this.state.MediaModalInfo.type ===
                          HotspotType.video && (
                          <div className="media-modal-content">
                            <ReactPlayer
                              config={{
                                youtube: {
                                  playerVars: { showinfo: 1 },
                                },
                              }}
                              playing={true}
                              url={this.state.MediaModalInfo.link}
                              volume={0.5}
                              controls={false}
                              width="100%"
                              height="100%"
                            />
                          </div>
                        )}
                        {this.state.MediaModalInfo.type ===
                          HotspotType.image && (
                          <img
                            title={"image"}
                            className="media-modal-content-image"
                            src={this.state.MediaModalInfo.link}
                            alt="mediaModalImage"
                          ></img>
                        )}
                        {this.state.MediaModalInfo.type !==
                          HotspotType.videoPlayer && (
                          <img
                            src={ImageString.CLOSEBUTTON}
                            className="closeButton-mediaModal"
                            alt="MediaModalCLoseButton"
                            onClick={this.closeMediaModal}
                          ></img>
                        )}
                      </div>
                    </article>
                  </>
                )}
                {this.state.UI.activeMenu.id === menuItemsId.Lobby && (
                  <>
                    <Scene
                      ShowMediaModal={this.showMediaModal}
                      initialVideo={VideoString.LOBBYLOOP}
                      firstVideoFrame={ImageString.LOBBYLOOP}
                      initialHotspot={LobbyHotspots}
                      globalBackButton={false}
                      changeComponenet={this.handleClick}
                      ratio={videoRatios}
                      // for tut to work also pass name
                      sceneName="Lobby"
                      showTut={true}
                      tutComponent={LobbyTut}
                      showingTutorialEvent={this.handleSceneTutorial} //for handling transition pass start and end
                      changeInternalStateHandler={this.handleLobbyInternalState}
                      //Analytics
                      addHotspotAnalytics={this.handleHotspotAnalytics}
                      addAnalytics={(value) =>
                        this.addComponentAnalytics(
                          AnalyticsLocations.Lobby,
                          value
                        )
                      }
                    ></Scene>
                    {this.state.UI.showHelpdeskChat && (
                      <>
                        {this.context.email &&
                          this.state.HelpdeskChat.showChat && (
                            <>
                              {/* <NotLive continue={this.handleHelpdeskChatClose}></NotLive> */}
                              <ClientChat
                                channel={this.context.email}
                                user={{
                                  id: this.context.email,
                                  name: this.context.displayName,
                                }}
                                closeChat={this.handleHelpdeskChatClose}
                                visibility={true}
                              ></ClientChat>
                            </>
                          )}
                        {this.state.HelpdeskChat.showButton && (
                          <>
                            {/* <a data-tip data-for="chatbotInfo" >
                                                    <img
                                                        className={`helpdeskchatbtn`}
                                                        src="assets/images/chatbot.png"
                                                        alt="helpdeskButton"
                                                        onClick={this.handleHelpdeskChatbutton}
                                                    />
                                                </a>
                                                <ReactTooltip id='chatbotInfo' >
                                                    <span>How may I help you ?</span>
                                                </ReactTooltip> */}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
                {this.state.UI.activeMenu.id === menuItemsId.Audi && (
                  <AudiScene
                    changeComponenet={this.handleClick}
                    ShowMediaModal={this.showMediaModal}
                    initialVideo={AudiData.introVideo}
                    isImageScene={isIOS || window.parent.isMac15}
                    initalImage={ImageString.Audi}
                    globalBackButton={false}
                    link={this.state.audiLnk}
                    framePlacementStyle={AudiData.placementStyle}
                    hiddeMute={false}
                    toggleRotateScreen={this.props.toggleRotateScreen}
                    showScreenCover={false}
                    showControl={false}
                    //For Tutorial
                    sceneName="audi"
                    showTut={true}
                    tutComponent={AudiTut}
                    subMenus={this.state.UI.activeSubMenu}
                    //Analytics
                  ></AudiScene>
                )}
                {this.state.UI.activeMenu.id === menuItemsId.Explore && (
                  <>
                    <Scene
                      ShowMediaModal={this.showMediaModal}
                      initialVideo={VideoString.BREAKOUTLOOP}
                      firstVideoFrame={ImageString.Breakout}
                      initialHotspot={ResourceCenterHotspot}
                      globalBackButton={false}
                      changeComponenet={this.handleClick}
                      ratio={videoRatios}
                      // for tut to work also pass name
                      sceneName="Networking"
                      showTut={false}
                      tutComponent={NetworkingTut}
                      showingTutorialEvent={this.handleSceneTutorial} //for handling transition pass start and end
                      //  changeInternalStateHandler={this.handleLobbyInternalState}
                      //Analytics
                      addHotspotAnalytics={this.handleHotspotAnalytics}
                      addAnalytics={(value) =>
                        this.addComponentAnalytics(
                          AnalyticsLocations.Networking,
                          value
                        )
                      }
                    ></Scene>
                  </>
                )}
                {this.state.UI.activeMenu.id === menuItemsId.Meet && (
                  <>
                    <Scene
                      isImageScene={true}
                      initalImage={ImageString.ProductStall}
                      ShowMediaModal={this.showMediaModal}
                      // initialVideo={VideoString.BREAKOUTLOOP}
                      // firstVideoFrame={ImageString.Breakout}
                      initialHotspot={MeetTheHeroHotspot}
                      globalBackButton={true}
                      changeComponenet={this.handleClick}
                      ratio={videoRatios}
                      // for tut to work also pass name
                      sceneName="Networking"
                      showTut={false}
                      tutComponent={NetworkingTut}
                      showingTutorialEvent={this.handleSceneTutorial} //for handling transition pass start and end
                      //  changeInternalStateHandler={this.handleLobbyInternalState}
                      //Analytics
                      addHotspotAnalytics={this.handleHotspotAnalytics}
                      addAnalytics={(value) =>
                        this.addComponentAnalytics(
                          AnalyticsLocations.MeetTheHero,
                          value
                        )
                      }
                    ></Scene>
                  </>
                )}
                {this.state.UI.overlayMenu && (
                  <>
                    {this.state.UI.overlayMenu.id ===
                      menuItemsId.TeamBuilding && (
                      <>
                        <Connect
                          close={this.hideOverlayMenu}
                          addAnalytics={(value) =>
                            this.addComponentAnalytics(
                              AnalyticsLocations.Teambuilding,
                              value
                            )
                          }
                        ></Connect>
                        {/* <ListContainer
                                            hideOverlayMenu={this.hideOverlayMenu}
                                            handleTeamOptionSelection={this.handleTeamOptionSelection}
                                            //Analytics
                                            addAnalytics={(value) => {
                                                this.addComponentAnalytics(AnalyticsLocations.Teambuilding, value);
                                                if (value)
                                                    this.addScorePoints(PointType.TeamBuilding)
                                            }}
                                        ></ListContainer> */}
                      </>
                    )}
                    {/* {
                                    (this.state.UI.overlayMenu.id === menuItemsId.TeamBuilding || this.state.UI.overlayMenu.id === menuItemsId.Audi) &&
                                    <NotLive continue={this.hideOverlayMenu}></NotLive>
                                } */}
                  </>
                )}
                {this.state.UI.activeMenu.id === menuItemsId.Explore &&
                  this.state.UI.activeSubMenu && (
                    <>
                      {this.state.UI.activeSubMenu.id ===
                        NetworkingSubmenuId.Speaker && (
                        <article
                          className={`img-bg videoBox h-100 zIndex50`}
                          id="play"
                        >
                          <div className="media-modal">
                            <iframe
                              title={"pdf"}
                              className="media-modal-content"
                              src={NetworkingLounge.speakerPdf}
                            ></iframe>
                            <img
                              src={ImageString.CLOSEBUTTON}
                              className="closeButton-mediaModal"
                              alt="MediaModalCLoseButton"
                              onClick={this.closeSubMenu}
                            ></img>
                          </div>
                        </article>
                      )}
                      {this.state.UI.activeSubMenu.id ===
                        NetworkingSubmenuId.Sessions && (
                        <>
                          <VideoPlayer
                            videoData={VideoPlayerData}
                            close={() => this.closeSubMenu()}
                          ></VideoPlayer>
                        </>
                      )}
                      {this.state.UI.activeSubMenu.id ===
                        NetworkingSubmenuId.Connect && (
                        <Connect close={this.closeSubMenu}></Connect>
                      )}
                      {this.state.UI.activeSubMenu.id ===
                        NetworkingSubmenuId.Chat && (
                        <div className="scrollableIframeParent">
                          <iframe
                            className="publicChatBox"
                            src="/Chat/index.html"
                            title="chatFrame"
                          ></iframe>
                        </div>
                      )}
                    </>
                  )}
                {/* {
                            this.state.UI.showLeaderboardButton && this.state.UI.activeMenu.id === menuItemsId.Lobby && !this.state.UI.showHelpdeskChat && !this.state.UI.overlayMenu &&
                            <div className="leaderboardButton" onClick={this.handleLeaderboardButton}>
                                <div className="leaderboardIcon"></div>Leaderboard
                            </div>
                        } */}
                {/* {
                            this.state.UI.showLeaderboardButton && this.canShowLeaderboardInNet() &&
                            <div className="leaderboardButton" onClick={this.handleLeaderboardButton}>
                                <div className="leaderboardIcon"></div>Leaderboard
                            </div>
                        } */}
                {/* {
                            this.state.UI.showLeaderboard &&
                            <Leaderboard close={this.handleLeaderboardClose}></Leaderboard>
                        } */}
                {
                  <footer className={`footerBox  ${isIOS ? "footer-ios" : ""}`}>
                    {this.state.UI.overlayMenu && (
                      <>
                        {this.state.UI.overlayMenu.id ===
                          menuItemsId.MyProfile && (
                          <div>
                            <MyProfile
                              close={this.hideOverlayMenu}
                              ShowMediaModal={this.handleMedialModalFromOverlay}
                            ></MyProfile>
                          </div>
                        )}
                      </>
                    )}
                    {this.state.UI.activeMenu &&
                      !this.state.UI.activeMenu.subMenus && (
                        <Menu
                          items={this.state.UI.menuItems}
                          mainMenuState={
                            this.state.UI.overlayMenu
                              ? this.state.UI.overlayMenu.id
                              : this.state.UI.activeMenu.id
                          }
                          onMenuItemClick={this.handleClick}
                          canInteract={this.state.UI.isInteractable}
                        ></Menu>
                      )}
                    {this.state.UI.activeMenu.subMenus && (
                      <Menu
                        items={this.state.UI.activeMenu.subMenus}
                        mainMenuState={
                          this.state.UI.activeSubMenu
                            ? this.state.UI.activeSubMenu.id
                            : -1
                        }
                        onMenuItemClick={this.handleSubmenuItemClick}
                        canInteract={this.state.UI.isInteractable}
                        backButton={false}
                        onBackButtonClick={(e) =>
                          this.handleClick(e, menuItems[menuItemsIndex.Lobby])
                        }
                      ></Menu>
                    )}
                    {this.state.UI.activeSubMenu &&
                      this.state.UI.activeMenu.id === menuItemsId.Audi && (
                        <>
                          {this.state.UI.activeSubMenu.id ===
                            AudisubMenuId.QNA && (
                            <div
                              className={`submenu-container active expended  ${
                                isIOS ? "ipad-submenu-container" : ""
                              }`}
                              style={
                                isTablet ? { height: "calc(100% - 7rem)" } : {}
                              }
                            >
                              <QNA onHeadingClick={this.closeSubMenu}></QNA>
                            </div>
                          )}
                        </>
                      )}
                    {window.vote &&
                      this.state.UI.activeMenu.id === menuItemsId.Audi && (
                        <div
                          className={`submenu-container active expended 
                        ${
                          this.state.UI.activeSubMenu &&
                          this.state.UI.activeSubMenu.id === AudisubMenuId.POll
                            ? ""
                            : "d-none"
                        }`}
                          style={
                            isTablet ? { height: "calc(100% - 7rem)" } : {}
                          }
                        >
                          <PublicPoll
                            PollAdmin_Col={AppString.PollAdmin_Col}
                            PollAdmin_Doc={AppString.PollAdmin_Doc}
                            Poll_Doc={AppString.Poll_Doc}
                            PollResponse_Col={"pollResponse"}
                            onHeadingClick={this.closeSubMenu}
                            forceOpen={(e) =>
                              this.handleSubmenuItemClick(e, {
                                id: 2,
                                name: "Poll",
                                class: "icon-Poll",
                                get enable() {
                                  return window.vote;
                                },
                              })
                            }
                          ></PublicPoll>
                        </div>
                      )}
                    {this.state.UI.activeSubMenu &&
                      this.state.UI.activeMenu.id === menuItemsId.Audi && (
                        <>
                          {this.state.UI.activeSubMenu.id ===
                            AudisubMenuId.Profile && (
                            <div>
                              <MyProfile
                                close={this.closeSubMenu}
                                ShowMediaModal={
                                  this.handleMedialModalFromOverlay
                                }
                              ></MyProfile>
                            </div>
                          )}
                        </>
                      )}
                  </footer>
                }
              </section>
            </div>
          </>
        )}

        {this.checkIfAudioCanPlayUnderMediaModal() &&
          this.state.UI.activeMenu.id !== menuItemsId.Explore &&
          this.state.UI.activeMenu.id !== menuItemsId.Audi &&
          this.state.UI.activeMenu.id !== menuItemsId.product && (
            <ReactAudioPlayer
              src="/3dAssets/music/ambientMusic.mp3"
              autoPlay
              volume={0.1}
              loop={true}
              preload="auto"
              ref={this.currentAudioRef}
            />
          )}
        {/* {!(
          isMobileOnly && this.state.UI.activeMenu.id === menuItemsId.Audi
        ) && (
          <div className="leftBottom">
            <img src="/assets/images/D2C.png"></img>
          </div>
        )} */}
      </>
    );
  }
}
Home.contextType = UserContext;

export default Home;
