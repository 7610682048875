import { firestore } from "../../firebase/firebase";
import React, { Component } from "react";
import "./Login.css";
import {
  realDB,
  loadUser,
  getUserDetails,
  getUserDetailsNew,
} from "../../firebase/firebase";
import { ImageString } from "../../../const/assets/ImageString";
import "./Login.css";
import { isMobileOnly } from "react-device-detect";
import landing_page_img from "./assets/Lobby_Loop_Moment 1.png";
import logo from "./assets/Vector.png";
import pc_bg from "./pc_bg.jpg";
import graphicTop from "./graphicTop.png";
import graphicBottom from "./graphicBottom.png";
import ttl1 from "./ttl1.png";
import ttl2 from "./ttl2.png";
import connectBtn from "./connect.png";

class Login extends Component {
  state = {
    email: "",
    name: "",
    passcode: "",
    uid: "",
    error: false,
    errorMessage: "",
    _loading: false,
    forceDisable: false,
    forceUpdate: false,
  };
  videoRef = React.createRef();

  componentDidMount = () => {
    window.showLoginError = this.showLoginError;
    if (!isMobileOnly) window.addEventListener("click", this.playVideo);
  };

  playVideo = () => {
    if (this.videoRef) {
      // this.videoRef.current.play();
      // this.videoRef.current.volume = 0.25;
    } else {
      setTimeout(() => this.playVideo(), 250);
    }
  };

  componentWillUnmount = () => {
    if (!isMobileOnly) window.removeEventListener("click", this.playVideo);
    if (this.videoRef) {
      if (this.videoRef.current) this.videoRef.current.remove();
    }
  };

  onInputChange = (event) => {
    event.preventDefault();
    let value = event.target.value;
    this.setState({ [event.target.name]: value });
  };

  showLoginError = (err) => {
    this.setState({
      error: true,
      errorMessage: err.message ? err.message : err,
    });
  };

  validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  checkPasscode = (userPasscode) => {};

  onFormSubmit = async (event) => {
    event.preventDefault();
    try {
      this.setState({
        error: false,
        forceDisable: true,
      });

      if (!this.validateEmail(this.state.email.trim().toLocaleLowerCase())) {
        throw { code: "email error" };
      }

      const userEmail = `${this.state.email.trim().toLowerCase()}`;
      const password = `${userEmail}123456`;

      let name = this.state.name.replace(/\s+/g, "").toLowerCase();
      window.name = name.toLocaleUpperCase();
      // const userEmail = `${this.state.uid.toLowerCase().trim()}@event.com`;
      // console.log(this.state.uid)
      // window.license = this.state.uid;
      // const password = `${userEmail}`;
      // const doc = await firestore.collection("profile").doc(this.state.uid).get();
      //  window.name = doc.data().name;
      // if(doc.exists){
      //   window.title=doc.data().title
      //   console.log("title")
      //   console.log("title"+window.title)
      //   await loadUser(userEmail, password, window.name, true);
      // } else {
      //   this.setState({
      //     error: true,
      //     errorMessage: "User not registered",
      //     forceDisable: false,
      //   });
      // }

      await loadUser(userEmail, password, true, this.state.name.trim());

      window.userData = getUserDetailsNew(userEmail);
      // let email = this.state.email.toLowerCase();
      // let name = this.state.name;
      // let password = `${email}123456`
    } catch (err) {
      this.setState({
        forceDisable: false,
      });
      let error = "";
      switch (err.code) {
        case "auth/wrong-password":
          error = "Invalid username and password.";
          break;
        case "auth/user-not-found":
          error = "User not registered";
          break;
        case "auth/too-many-requests":
          error =
            "Too many invalid requests, please wait for 60 seconds before retrying";
          break;
        case "NoUserFound":
          error = "User not registered";
          break;
        default:
          error = err.message;
      }
      this.setState({
        error: true,
        errorMessage: error,
      });
    }
  };

  checkUserStatus = async (userId) => {
    return new Promise((resolve, reject) => {
      if (userId == undefined) {
        reject({
          code: "UserIDWasNull",
          message: "Please try again later",
        });
      } else {
        if (userId === "") {
          reject({
            code: "UserIDWasNull",
            message: "Please try again later",
          });
        }
      }
      var userCheck = realDB.ref("loggedInUser/" + userId);
      userCheck.once("value").then(
        (snapshot) => {
          if (snapshot.exists()) {
            let data = snapshot.val();
            if (data.state === "online") {
              let err = {
                code: "AlreadyLogged",
                message: "User Logged in from another system.",
              };
              reject(err);
            } else {
              resolve();
            }
          } else {
            resolve();
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
  };

  render() {
    if (this.state._loading) {
      return <img alt="loading" src="/images/loader.gif" />;
    }
    return (
      <>
        {/* {
        isMobileOnly &&   <div 
          style={{
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
            position:"absolute",
            height:"100vh",
            width:"100vw",
            top:"0",
            left:"0",
            background:"black",
            zIndex:"10000000",
            color:"white",
            fontSize:"20px"
          }}>
          Desktop support only.
        </div>
      } */}
        <div className="login-main-cont">
          <div className="background">
            <img src={pc_bg} alt="" className="background-img" />
          </div>

          <div className="form-container">
            <div className="form">
              {!this.props.showLoggingIn && (
                <>
                  <form onSubmit={this.onFormSubmit}>
                    <div className="signinBox__body pd-t70">
                      {/* <img src={graphicTop} alt="" className="graphicTop" /> */}
                      {/* <img
                        src={graphicBottom}
                        alt=""
                        className="graphicBottom"
                      /> */}

                      <div
                        className="form-group mg-b50"
                        style={{
                          margin: "0% 0",
                        }}
                      >
                        <input
                          type="text"
                          className="form-control inputOne"
                          name="name"
                          value={this.state.name}
                          placeholder="Enter Your Name"
                          onChange={this.onInputChange}
                          required={true}
                          onFocus={() => {
                            this.props.toggleRotateScreen(false);
                          }}
                          onBlur={() => {
                            this.props.toggleRotateScreen(true);
                          }}
                        />
                      </div>
                      <div className="form-group mg-b50">
                        <input
                          type="text"
                          className="form-control inputTWo"
                          name="email"
                          value={this.state.email}
                          placeholder="Enter Your Email"
                          onChange={this.onInputChange}
                          required={true}
                          onFocus={() => {
                            this.props.toggleRotateScreen(false);
                          }}
                          onBlur={() => {
                            this.props.toggleRotateScreen(true);
                          }}
                        />
                      </div>
                      {this.state.error && (
                        <div
                          className="mg-b50"
                          style={{ color: "red", fontSize: "1.25rem" }}
                        >
                          {/* {this.state.errorMessage} */}
                          Enter valid info
                        </div>
                      )}
                      <div
                        className="btn-cont"
                        style={{
                          zIndex: "1",
                        }}
                      >
                        <input
                          type="image"
                          src={connectBtn}
                          className="loginBtn"
                          name="submit"
                          disabled={this.state.forceDisable ? true : false}
                          style={{
                            marginLeft: "-50%",
                            width: "50%",
                          }}
                        />
                      </div>
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="rotate-screen-popup">
          <div id="rotate">
            <div className="phone"></div>
            <div className="message">Please rotate your device!</div>
          </div>
        </div>
      </>
    );
  }
}

export default Login;
