import React, { Component } from "react";
import UserContext from "./UserContext";
import { auth, updateUserStatus, addLoginAnalytics, addDisconnectListener } from "../../firebase/firebase";

class UserProvider extends Component {
    state = {
        user: null
    };

    checkUserType = (email) =>{
        if(email.includes('heroexe')){
            window.jry = false
        }else if(email.includes('herojur')){
            window.jry = true
        }
    }

    componentDidMount = async () => {
        auth.onAuthStateChanged(async userAuth => {
            if (userAuth) {
                window.name = userAuth.displayName;
                console.log(userAuth.email, userAuth.displayName)
                this.checkUserType(userAuth.email)
                window.app.appConfig(userAuth.email);
                this.setState({
                    user: userAuth
                })
                
                let Id = userAuth.email;
                Id = Id.replace(/[&\/\\#,+$~%.'":*?<>{}]/g, '');
                Id = Id.toLowerCase();

                // await checkUserStatus(Id);
                await updateUserStatus(Id)

                addLoginAnalytics(userAuth).then(() => {
                });

                addDisconnectListener(userAuth)

                if (localStorage.getItem("viewedIntroOnce")) {
                    userAuth.secondTime = true;
                }
                
            } else {
                localStorage.clear();
                sessionStorage.clear();
                window.jry = false;
                this.setState({
                    user: null
                })
                window.name=null 
            }
        });
    };
    render() {
        const { user } = this.state;

        return (
            <UserContext.Provider value={user}>
                {this.props.children}
            </UserContext.Provider>
        );
    }
}

export default UserProvider;
