// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";
import { ImageString } from "../../../const/assets/ImageString";
import "./Menu.css";
import {
  addLogoutAnalytics,
  firestore,
  getUserScore,
  signOut,
} from "../../firebase/firebase";
import { UserContext } from "../../auth/providers";

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: this.props.items,
      activeItemId: this.props.mainMenuState,
    };
    this.onMenuItemClick = this.onMenuItemClick.bind(this);
  }
  componentDidMount = async () => {
    window.pro = this;

    let user = {
      name: this.context.displayName ? this.context.displayName : "User",
      email: this.context.email ? this.context.email : "userEmail@example.com",
    };
    console.log(this.context);
    this.setState({ ...user });
    // const score = await getUserScore(this.context)
    // this.setState({
    //     score: score
    // })
    // if (window.userData) {
    //     let Id = this.context.email.split('@')[0];
    //     const userData = await this.getUserdata(Id);
    //     window.userData = userData;
    //     this.setState(window.userData);
    // } else {
    //     this.setState(window.userData);
    // }
  };
  componentWillReceiveProps(newProps) {
    if (newProps.mainMenuState !== undefined) {
      this.setState({
        activeItemId: newProps.mainMenuState,
      });
    }
  }

  onMenuItemClick(event, item) {
    if (!this.props.canInteract) {
      return;
    }

    this.setState({ activeItemId: item.id });
    this.props.onMenuItemClick(event, item);
  }

  getPathRender(value) {
    if (value > 0) {
      var arr = [];
      for (var i = 0; i < value; i++) {
        arr.push(<span key={i} className={`path${i + 1}`}></span>);
      }
      return arr;
    }
  }

  render() {
    const { items, activeItemId } = this.state;

    return (
      <>
        <div className="desktop__logo">
          <img src={ImageString.MENULOGO} alt="Logo" />
        </div>
        <ul className="bottom-icons-nav bottom-icons-nav--withtext">
          {this.props.backButton && (
            <li
              key={"blackButton"}
              className="menuBackButton"
              onClick={(event) => this.props.onBackButtonClick(event)}
            >
              <a style={{ justifyContent: "center" }}>
                <span> &lt; Back</span>
              </a>
            </li>
          )}
          {items.map((item) => (
            <>
              {
                item.enable && 
                <li
                key={item.id}
                onClick={(event) => this.onMenuItemClick(event, item)}
                className="menu-items"
                >
                  <a className={item.id == activeItemId ? "active" : ""}>
                    <i className={item.class}></i>
                    <span>{item.name}</span>
                  </a>
                </li>
              }
            </>
          ))}
        </ul>
      </>
    );
  }
}
export default Menu;
