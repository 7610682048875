const videoRoot = "/3dAssets/videos/";
export const VideoString = {
  LOBBYLOOP: videoRoot + "Lobby_Loop.mp4", //"Lobby_Loop.mp4",
  LOOBYTOINFO: videoRoot + "ToInfoBooth.mp4",
  LOBBYTOAudi: videoRoot + "ToAuditorium.mp4",
  LOOBYTOPRODUCTSTALL: videoRoot + "ToProductStall.mp4",
  LOBBYTOBREAKOUT: videoRoot + "breakout.mp4",
  BREAKOUTLOOP: videoRoot + "breakoutLoop.mp4",
  AUDITORIUM: videoRoot + "FLT_Auditorium.mp4",
};
