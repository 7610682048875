export const AppString = {
  QNA: "qna-bd",
  QNAREPLY: "qnaReply",
  PollAdmin_Col: "pollAdmin",
  Poll_Doc: "poll",
  PollAdmin_Doc: "adminAccess",
  Dailyco_Doc: "",
  Dailyco_Col: "dailyco-bd",

  BACKSATGE: "backstage",
  BACKSTAGELINK: "staticData",
  BACKSTAGEREALTIMEDATA: "realtimeData",

  SPECIALUSER: "specialUser",
  USERS_COLLECTION: "users",
  CONNECT: "connect",
};
