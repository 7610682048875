import { VideoType } from "../../const/fixed/Types";

export const VideoPlayerData = {
    'video-4': {
        enabled: true,
        type: VideoType.vimeo,
        id: 'video-4',
        link: 'https://vimeo.com/497278291',
        // link: 'https://www.youtube.com/watch?v=zh77HuSacMo',
        name: 'Hero Xtreme 160R',
        videoCode: '1029115797',
        description: ''
    },
    'video-1': {
        enabled: true,
        type: VideoType.vimeo,
        id: 'video-1',
        link: 'https://vimeo.com/497282182',
        // link: 'https://www.youtube.com/watch?v=zh77HuSacMo',
        name: 'Hum Mein Hai Hero',
        videoCode: '1029123383',
        description: ''
    },
    'video-2': {
        enabled: true,
        type: VideoType.vimeo,
        id: 'video-2',
        link: 'https://vimeo.com/497281609',
        // link: 'https://www.youtube.com/watch?v=zh77HuSacMo',
        name: '#HeroXtreme160RStreetKing',
        videoCode: '1029121804',
        description: ''
    },
    'video-3': {
        enabled: true,
        type: VideoType.vimeo,
        id: 'video-3',
        link: 'https://vimeo.com/497280648',
        // link: 'https://www.youtube.com/watch?v=zh77HuSacMo',
        name: 'Hero Destini 125',
        videoCode: '1029120038',
        description: ''
    },
   
}

export const PDFPlayerData = {
    'optum-1': {
        id: 'optum-1',
        link: '/web/viewer.html?file=%2Fassets%2Fcontent%2FOptum.pdf',
        name: 'Optum Shell Sheet',
        thumbnail: '',
        description: ''
    },
}
