import React, { useState } from "react";
import Firebase, { firestore, auth, firebaseApp } from "../firebase/firebase";
import swal from "sweetalert";
import moment from "moment";
import { AppString } from "../firebase/AppString";

class CreateArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: "",
      user: {},
      userData: window.userData,
    };
  }

  componentDidMount() {
    this.authListener();
  }

  authListener() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({ user });
      } else {
        this.setState({ user: null });
      }
    });
  }

  onSubmit(e) {
    e.preventDefault();

    var date = moment().utcOffset("+05:30").format("hh:mm A DD-MM-YYYY");

    firestore
      .collection(AppString.QNA)
      .add({
        name: this.state.user.displayName
          ? this.state.user.displayName
          : this.state.user.email,
        title: this.state.user.displayName
          ? this.state.user.displayName
          : this.state.userData
          ? this.state.userData.name
          : this.context.displayName,

        content: this.state.content,
        time: new Date(),
        time2: date,
        timestamp: firebaseApp.firestore.FieldValue.serverTimestamp(),
        status: "0",
      })
      .then(() => {
        this.setState({ content: "" });
        document
          .getElementById("scrolltoview")
          .scrollIntoView({ behavior: "smooth" });
        swal("Question Submitted!", "Your Question is waiting for Approval.");
      });
  }

  render() {
    return (
      <form className="qa-section__input" onSubmit={this.onSubmit.bind(this)}>
        <input
          className="form-control-qna"
          name="content"
          onChange={(e) => this.setState({ content: e.currentTarget.value })}
          value={this.state.content}
          required
          placeholder="Enter Your Question"
          autoCorrect="off"
          autoComplete="off"
          onFocus={this.props.onFocus}
          onBlur={this.props.onBlur}
        />
        <button className="form-control-qna__btn">
          <i className="icon-send"></i>
        </button>
      </form>
    );
  }
}
export default CreateArea;
