import React, { Component } from "react";
import { attachConnectProfilesListener, removeConnectProfilesListener } from "../firebase/firebase";
import './Connect.css'
const HeaderBackground = {
    // background: 'url(/assets/images/background.png)',
    // backgroundPosition: 'center',
    // backgroundSize: 'contain'
    background: '#fff',
}
const LogoLeft = {
    background: 'url(/assets/images/tutHeader.png)',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat'
}
const LogoRight = {
    background: 'url(/assets/images/header.png)',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat'
}
const closeButton = {
    background: 'url(/assets/images/close.png)',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat'
}


export const connectUserData = {
    'SANJAY BHAN': {
        name: " SANJAY BHAN",
        designation: "Head – Global Business",
        linked_ln_profile: "https://www.linkedin.com/in/sanjay-bhan-33ba4714",
        email: '',
        enabled: true,
        profile_image: "https://storage.googleapis.com/hero-virtual.appspot.com/profilePictures/Mr.%20Sanjay%20Bhan.jpg"
    },
    'NIRANJAN GUPTA': {
        name: " NIRANJAN GUPTA",
        designation: "Chief Financial Officer ",
        linked_ln_profile: "https://www.linkedin.com/in/niranjan-gupta-8b981619",
        email: '',
        enabled: true,
        profile_image: "https://storage.googleapis.com/hero-virtual.appspot.com/profilePictures/Mr.%20Niranjan%20Gupta.png"
    },
    'NEERJA SHARMA': {
        name: "NEERJA SHARMA",
        designation: "Company Secretary & Chief Compliance Officer",
        linked_ln_profile: "https://www.linkedin.com/in/neerja-sharma-4b667961",
        email: '',
        enabled: true,
        profile_image: "https://storage.googleapis.com/hero-virtual.appspot.com/profilePictures/Ms.%20Neerja%20Sharma.jpg"
    },
    'MALO LE MASSON': {
        name: "MALO LE MASSON",
        designation: "Head – Strategy & Global  Product Planning ",
        linked_ln_profile: "https://www.linkedin.com/in/malo-le-masson",
        email: '',
        enabled: true,
        profile_image: "https://storage.googleapis.com/hero-virtual.appspot.com/profilePictures/Mr.%20Malo%20Le%20Masson.png"
    },
    'VIJAY SETHI': {
        name: "VIJAY SETHI",
        designation: "Chief Information Officer & Head Corporate Social Responsibility ",
        linked_ln_profile: "https://www.linkedin.com/in/vijay-sethi-43a52759",
        email: '',
        enabled: true,
        profile_image: "https://storage.googleapis.com/hero-virtual.appspot.com/profilePictures/Mr.%20Vijay%20Sethi.png"
    },
    'Michael Clarke': {
        name: "Michael Clarke",
        designation: "Chief Operating Officer & Chief Human Resource Officer",
        linked_ln_profile: "https://www.linkedin.com/in/mike-clarke-b2739911/",
        email: '',
        enabled: true,
        profile_image: "https://storage.googleapis.com/hero-virtual.appspot.com/profilePictures/Michael%20Clarke.png"
    },
    'GURINDERSINGH': {
        name: "GURINDER SINGH SANDHU",
        designation: "Head – Marketing ",
        linked_ln_profile: "https://www.linkedin.com/in/gurinder-sandhu-056a602",
        email: '',
        enabled: true,
        profile_image: "https://storage.googleapis.com/hero-virtual.appspot.com/profilePictures/Mr.%20Gurinder%20Sandhu.png"
    },
    'NAVEEN CHAUHAN': {
        name: " NAVEEN CHAUHAN",
        designation: "Head of Sales and After Sales.",
        linked_ln_profile: "https://www.linkedin.com/in/naveen-chauhan-50679421",
        email: '',
        enabled: true,
        profile_image: "https://storage.googleapis.com/hero-virtual.appspot.com/profilePictures/Mr.%20Naveen%20Chauhan.png"
    },
    
    'RAKESH VASISHT': {
        name: " RAKESH VASISHT",
        designation: "Head – Corporate Administration  & Executive Sponsor :Scooter Category ",
        linked_ln_profile: "https://www.linkedin.com/in/rakesh-vasisht-71abb49",
        email: '',
        enabled: true,
        profile_image: "https://storage.googleapis.com/hero-virtual.appspot.com/profilePictures/Mr.%20Rakesh%20Vashisht.jpg"
    },
 
    'MAHESH KAIKINI': {
        name: "MAHESH KAIKINI",
        designation: "Chief Quality Officer",
        linked_ln_profile: "https://www.linkedin.com/in/mahesh-kaikini-14934131",
        email: '',
        enabled: true,
        profile_image: "https://storage.googleapis.com/hero-virtual.appspot.com/profilePictures/Mr.%20Mahesh%20Kaikini.jpg",
    },
  
    'NEERAJ MATHUR': {
        name: "NEERAJ MATHUR",
        designation: "Head- Strategic Sourcing & Supply Chain",
        linked_ln_profile: "https://www.linkedin.com/in/neeraj-mathur-65679b14",
        email: '',
        enabled: true,
        profile_image: "https://storage.googleapis.com/hero-virtual.appspot.com/profilePictures/Mr.%20Neeraj%20Mathur.png"
    },
    'RAVI PISIPATY': {
        name: "RAVI PISIPATY",
        designation: "Head of Plant Operations",
        linked_ln_profile: "https://www.linkedin.com/in/ravi-pisipaty-a8341827",
        email: '',
        enabled: true,
        profile_image: "https://storage.googleapis.com/hero-virtual.appspot.com/profilePictures/Mr.%20Ravi%20Pisipaty.jpg",
    },
    'SURESH JAGIRDAR': {
        name: "SURESH JAGIRDAR",
        designation: "General Counsel & Head - Legal",
        linked_ln_profile: "https://www.linkedin.com/in/suresh-jagirdar-48418b32",
        email: '',
        enabled: true,
        profile_image: "https://storage.googleapis.com/hero-virtual.appspot.com/profilePictures/Mr.%20Suresh%20Jagirdar.png"
    },
    'VIKRAM KASBEKAR': {
        name: "VIKRAM KASBEKAR",
        designation: "Executive Director & Chief Technology Officer",
        linked_ln_profile: "https://www.linkedin.com/in/vikram-kasbekar-7297491",
        email: '',
        enabled: true,
        profile_image: "https://storage.googleapis.com/hero-virtual.appspot.com/profilePictures/Mr.%20Vikram%20Kasbekar.png"
    }
}

class Connect extends Component {

    state = {
        list: connectUserData
    }

    componentDidMount = () => {
        if (this.props.addAnalytics) {
            this.props.addAnalytics(true);
        }
        // attachConnectProfilesListener((err, data) => {
        //     if (err) {
        //         console.log(data)
        //         return;
        //     }
        //     console.log(data)
        //     this.setState({
        //         list: data
        //     })
        // })
    }

    componentWillUnmount = () => {
        if (this.props.addAnalytics) {
            this.props.addAnalytics(false);
        }
        // removeConnectProfilesListener();
    }

    handleConnectButton = (link) => {
        if (link.includes('http')) {
            window.open(link, "_blank")
            return;
        }
        link = 'https://' + link;
        window.open(link, "_blank")
    }

    render() {
        const { list } = this.state;
        return (
            <>
                <div className="connectContainer">
                    <div className="connectHeader " style={HeaderBackground}>
                        <div className="logo-left" style={LogoLeft}></div>
                        <div className="connect-closeButton" style={closeButton} onClick={(e) => this.props.close(e)}></div>
                        {/* <div className="logo-right" style={LogoRight}></div> */}
                    </div>
                    <div className="connectBody">
                        {
                            list &&
                            <>
                                {
                                    Object.keys(list).map(key => (
                                        <div className="connect-card" key={key}>
                                            <div className="connect-card-head ">
                                                <div className="connect-card-head-image" style={{
                                                    background: `url(${list[key].profile_image})`,
                                                    backgroundPosition: 'center',
                                                    backgroundSize: 'contain',
                                                    backgroundRepeat: 'no-repeat'
                                                }}></div>
                                                <div className="connect-card-head-details">
                                                    <div className="connect-card-name">{list[key].name}</div>
                                                    <div className="connect-card-title">{list[key].designation}</div>
                                                    <div className="connect-card-email">{list[key].email}</div>
                                                    {
                                                        list[key].linked_ln_profile &&
                                                        <div className="connect-card-button" onClick={(e) => this.handleConnectButton(list[key].linked_ln_profile)} >Connect</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="heroBorder"></div>
                                            {/* <div className="connect-card-body">
                                                <q>{list[key].what_keeps_you_amazing}</q>
                                                <div className="connect-card-button" onClick={(e) => this.handleConnectButton(list[key].linked_ln_profile)} >Connect</div>
                                            </div> */}
                                        </div>
                                    ))
                                }
                            </>
                        }
                    </div>
                </div>
            </>
        );
    }
}

export default Connect;
